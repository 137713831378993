import { ComponentProps, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useLocation } from '@reach/router';

import { useBlogArticle } from '../BlogArticleContext';

import Breadcrumbs, { Breadcrumb } from 'components/Breadcrumbs';
import { BASE_LANGUAGE, INTERNAL_LINKS } from 'utils/constants';
import { richTextWithPlaceholder } from 'utils/prismic';
import type { Normalize } from 'utils/types';

const normalizeBreadcrumbsData = (
  parentUrl: string,
  article: Queries.PrismicLinkType | null,
  breadcrumbs: Breadcrumb[] = []
) => {
  if (!article || !article?.document) return breadcrumbs;

  const {
    url,
    data: { compact_title, parent_article },
  } = article.document as Queries.PrismicBlogArticle;

  const normalizedUrl = url ?? INTERNAL_LINKS.BLOG;

  const appendedBreadcrumbs: Breadcrumb[] = [
    { url: normalizedUrl, label: compact_title ?? '...' },
    ...breadcrumbs,
  ];

  return normalizeBreadcrumbsData(parentUrl, parent_article, appendedBreadcrumbs);
};

const useBlogArchiveBreadcrumb = (): Breadcrumb => {
  const blogArchives = useBlogArticle().blogArchives.edges;
  const language = useBlogArticle().blogArticle.lang;

  const fallbackUrl = '/';

  return blogArchives.reduce(
    (acm, { node }) => {
      const { lang, url, data } = node;
      const label = data.title ?? acm.label;

      if (node.lang === language) return { url, label };

      if (acm.url === fallbackUrl && lang === BASE_LANGUAGE) return { url, label };

      return acm;
    },
    { url: fallbackUrl, label: 'Blog' }
  );
};

export const useBreadcrumbsData = (): ComponentProps<typeof Breadcrumbs> => {
  const { parent_article, compact_title } = useBlogArticle().blogArticle.data;

  const blogArchiveData = useBlogArchiveBreadcrumb();

  const articleBreadcrumbs = useMemo(
    () => normalizeBreadcrumbsData(blogArchiveData.url, parent_article as Queries.PrismicLinkType),
    [parent_article]
  );

  return {
    breadcrumbs: [blogArchiveData, ...articleBreadcrumbs],
    currentLabel: compact_title ?? '...',
  };
};

export const useAuthorData = () => {
  const { last_publication_date, data: { author, date_published } = {} } =
    useBlogArticle().blogArticle;

  return useMemo(() => {
    const { data: { name = 'ShakespeareMusic' } = {} } =
      (author?.document as Normalize<Queries.PrismicAuthor>) ?? {};

    return {
      name,
      date_published:
        date_published ??
        last_publication_date ??
        new Date().toLocaleString('en-us', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        }),
    };
  }, [author, date_published]);
};

export const useDescriptionData = () => {
  const { compact_title, title, description } = useBlogArticle().blogArticle.data;

  const { origin, pathname } = useLocation();

  return useMemo(
    () => ({
      description: richTextWithPlaceholder(description, ''),
      share: {
        url: `${origin}${pathname}`,
        title: title?.text ?? compact_title ?? 'ShakespeareMusic',
      },
    }),
    [origin, pathname, compact_title, title, description]
  );
};

export const useBannerData = () => {
  const { banner_image } = useBlogArticle().blogArticle.data;
  const { spacings } = useTheme();

  return useMemo(() => {
    if (!banner_image?.gatsbyImageData) return null;

    const desktopDimensions = banner_image.dimensions;
    const mobileImage = banner_image.thumbnails.Mobile;
    const mobileDimensions = mobileImage.dimensions;

    const baseAltText = banner_image?.alt ?? 'Article banner image';

    const desktopAspectRatio = desktopDimensions.width / desktopDimensions.height;

    const mobileMaxHeight = mobileDimensions.height - mobileDimensions.width / desktopAspectRatio;

    const breakpoint = mobileDimensions.width + 16 * 2;

    return {
      breakpoint,
      desktop: { alt: baseAltText, image: banner_image.gatsbyImageData },
      mobile: {
        alt: mobileImage?.alt ?? baseAltText,
        image: mobileImage.gatsbyImageData,
        maxHeight: mobileMaxHeight,
      },
    };
  }, [spacings, banner_image]);
};
