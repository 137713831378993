import React from 'react';
import { graphql } from 'gatsby';

import { useBreadcrumbsData } from './hooks';

import BreadcrumbsComponent from 'components/Breadcrumbs';
import { Classname } from 'utils/types';

const Breadcrumbs: React.FC<Classname> = ({ className }) => {
  const breadcrumbsProps = useBreadcrumbsData();

  return <BreadcrumbsComponent className={className} {...breadcrumbsProps} />;
};

export const breadcrumbsFragment = graphql`
  fragment BlogArticleHeroBreadcrumbsData on PrismicBlogArticleDataType {
    compact_title
    ...BlogArticleParent
  }

  fragment BlogArticleParent on PrismicBlogArticleDataType {
    parent_article {
      document {
        ... on PrismicBlogArticle {
          url
          data {
            compact_title
            ...BlogArticleRecursiveParent1
          }
        }
      }
    }
  }

  fragment BlogArticleRecursiveParent1 on PrismicBlogArticleDataType {
    parent_article {
      document {
        ... on PrismicBlogArticle {
          url
          data {
            compact_title
            ...BlogArticleRecursiveParent2
          }
        }
      }
    }
  }
  fragment BlogArticleRecursiveParent2 on PrismicBlogArticleDataType {
    parent_article {
      document {
        ... on PrismicBlogArticle {
          url
          data {
            compact_title
            ...BlogArticleRecursiveParent3
          }
        }
      }
    }
  }
  fragment BlogArticleRecursiveParent3 on PrismicBlogArticleDataType {
    parent_article {
      document {
        ... on PrismicBlogArticle {
          url
          data {
            compact_title
            ...BlogArticleRecursiveParent4
          }
        }
      }
    }
  }
  fragment BlogArticleRecursiveParent4 on PrismicBlogArticleDataType {
    parent_article {
      document {
        ... on PrismicBlogArticle {
          url
          data {
            compact_title
            ...BlogArticleRecursiveParent5
          }
        }
      }
    }
  }
  fragment BlogArticleRecursiveParent5 on PrismicBlogArticleDataType {
    parent_article {
      document {
        ... on PrismicBlogArticle {
          url
          data {
            compact_title
          }
        }
      }
    }
  }
`;

export default Breadcrumbs;
