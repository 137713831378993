import React, { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { graphql } from 'gatsby';

import { useDescriptionData } from './hooks';

import FacebookShareIcon from 'assets/svg/facebookShare.inline.svg';
import LinkedInShareIcon from 'assets/svg/linkedInShare.inline.svg';
import TwitterShareIcon from 'assets/svg/twitterShare.inline.svg';
import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-areas:
      'text .'
      'text .'
      'text .'
      'text .';
    align-items: flex-start;

    row-gap: 12px;
    column-gap: 48px;

    ${mq.md} {
      grid-template-columns: initial;
      grid-template-areas:
        '.    .    .    .'
        'text text text text';
      column-gap: 12px;
      row-gap: 32px;
    }
  `}
`;

const Text = styled(RichText)`
  ${({ theme: { mq } }) => css`
    grid-area: text;
    font-size: 20px;
    line-height: 33px;
    margin: 0;

    ${mq.md} {
      font-size: 16px;
      line-height: 24px;
    }
  `}
`;

type ShareButton<T extends React.FC> = { as: T } & ComponentProps<T>;

const ShareButton = styled.div<
  | ShareButton<typeof FacebookShareButton>
  | ShareButton<typeof LinkedinShareButton>
  | ShareButton<typeof TwitterShareButton>
>`
  ${({ theme: { durations } }) => css`
    & > svg {
      width: 32px;
      height: 32px;

      transition: opacity ${durations.regular};
    }
    &:hover {
      & > svg {
        opacity: 0.7;
      }
    }
    &:active {
      & > svg {
        opacity: 0.9;
      }
    }
  `}
`;

const Description: React.FC<Classname> = ({ className }) => {
  const { description, share } = useDescriptionData();

  return (
    <Container className={className}>
      <Text render={description} />
      <ShareButton as={FacebookShareButton} url={share.url} quote={share.title}>
        <FacebookShareIcon />
      </ShareButton>
      <ShareButton
        as={LinkedinShareButton}
        url={share.url}
        title={share.title}
        source="ShakespeareMusic"
      >
        <LinkedInShareIcon />
      </ShareButton>
      <ShareButton as={TwitterShareButton} url={share.url} title={share.title}>
        <TwitterShareIcon />
      </ShareButton>
    </Container>
  );
};

export const descriptionFragment = graphql`
  fragment BlogArticleHeroDescriptionData on PrismicBlogArticleDataType {
    compact_title
    title {
      text
    }
    description {
      richText
    }
  }
`;

export default Description;
