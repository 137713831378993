import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';

import { useFaqData } from './hooks';
import FAQ from './FAQ';

import { Classname } from 'utils/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Title = styled.h2`
  ${({ theme: { mq, tp } }) => css`
    ${tp.useFont(fonts => fonts.Inter.semiBold)}
    font-size: 64px;
    line-height: 77px;
    margin: 0 0 36px;

    ${mq.md} {
      font-size: 26px;
      line-height: 31.5px;
      margin-bottom: 20px;
    }
  `}
`;

const FaqSection: React.FC<Classname> = ({ className }) => {
  const frequentlyAskedQuestions = useFaqData();

  return frequentlyAskedQuestions.length ? (
    <Container className={className}>
      <Title>FAQs</Title>
      {frequentlyAskedQuestions.map(({ key, question, answer }) => (
        <FAQ key={key} question={question} answer={answer} />
      ))}
    </Container>
  ) : null;
};

export const faqSectionFragment = graphql`
  fragment BlogArticleFaqData on PrismicBlogArticle {
    data {
      frequently_asked_questions {
        ...BlogArticleFaqItemData
      }
    }
  }
`;

export default FaqSection;
