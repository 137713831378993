import { useBlogArticle } from '../BlogArticleContext';

export const useRelatedTopicsData = () => {
  const { related_articles } = useBlogArticle().blogArticle.data;

  return (
    related_articles?.map(
      node => node.related_article.document as Queries.BlogArticleThumbnailDataFragment
    ) ?? []
  );
};
