import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';

import { useAuthorData } from './hooks';

import { Classname } from 'utils/types';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 16px;
  align-items: center;
`;

const Name = styled.h5`
  ${({ theme: { tp } }) => css`
    ${tp.useFont(fonts => fonts.Inter.regular)}
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    white-space: nowrap;
  `}
`;

const DatePublished = styled.span`
  ${({ theme: { colors, mq } }) => css`
    justify-self: flex-end;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.black}70;

    ${mq.md} {
      justify-self: flex-start;
      grid-row: 2 / 3;
      grid-column: 1 / -1;
    }
  `}
`;

const Author: React.FC<Classname> = ({ className }) => {
  const { name, date_published } = useAuthorData();

  return (
    <Container className={className}>
      <Name>{name}</Name>
      <DatePublished>{date_published}</DatePublished>
    </Container>
  );
};

export const authorFragment = graphql`
  fragment BlogArticleHeroAuthorData on PrismicBlogArticleDataType {
    date_published(formatString: "MMM DD, YYYY")
    author {
      document {
        ... on PrismicAuthor {
          data {
            name
          }
        }
      }
    }
  }

  fragment BlogArticleLastPublishedData on PrismicBlogArticle {
    last_publication_date(formatString: "MMM DD, YYYY")
  }
`;
export default Author;
