import React, { useState } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { css, useTheme } from '@emotion/react';
import { RichTextBlock } from 'prismic-reactjs';
import AnimateHeight from 'react-animate-height';

import Chevron from './assets/chevron.inline.svg';

import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';

interface IFAQ extends Classname {
  question: RichTextBlock[];
  answer: RichTextBlock[];
}

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-areas: 'question chevron' 'answer answer';
    justify-content: center;
    align-items: center;

    padding: 16px 32px;
    border-radius: 8px;
    cursor: pointer;
    gap: 12px;

    background-color: ${colors.lightestGray};
  `}
`;

const Question = styled(RichText)`
  ${({ theme: { mq, tp } }) => css`
    grid-area: question;
    ${tp.useFont(fonts => fonts.Inter.semiBold)}
    font-size: 24px;
    line-height: 57px;

    ${mq.md} {
      font-size: 16px;
      line-height: 24px;
    }
  `}
`;

const Answer = styled(RichText)`
  grid-area: answer;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
`;

const StyledChevron = styled(Chevron)<{ 'is-open'?: string }>`
  ${({ theme: { durations }, ...props }) => css`
    grid-area: chevron;
    transition: transform ${durations.regular};
    transform: rotate(${props['is-open'] ? '180deg' : '0deg'});
  `}
`;

const FAQ: React.FC<IFAQ> = ({ className, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { durations } = useTheme();

  return (
    <Container className={className} onClick={() => setIsOpen(open => !open)}>
      <Question render={question} />
      <StyledChevron is-open={isOpen ? isOpen.toString() : void 0} />
      <AnimateHeight height={isOpen ? 'auto' : 0} duration={Number(durations.regular.slice(0, -2))}>
        <Answer render={answer} />
      </AnimateHeight>
    </Container>
  );
};

export const faqFragment = graphql`
  fragment BlogArticleFaqItemData on PrismicBlogArticleDataFrequentlyAskedQuestions {
    question {
      richText
      text
    }
    answer {
      richText
    }
  }
`;

export default FAQ;
