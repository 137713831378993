import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Author from './Author';
import Description from './Description';
import Banner from './Banner';
import Breadcrumbs from './Breadcrumbs';

import { useBlogArticle } from '../BlogArticleContext';

import { RichText } from 'components/Prismic';
import { Classname } from 'utils/types';

enum HEADLINE_AREAS {
  AUTHOR = 'author',
  BREADCRUMBS = 'breadcrumbs',
  TITLE = 'title',
  DESCRIPTION = 'description',
  BANNER = 'banner',
}

const Container = styled.section`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      '${HEADLINE_AREAS.AUTHOR}'
      '${HEADLINE_AREAS.BREADCRUMBS}'
      '${HEADLINE_AREAS.TITLE}'
      '${HEADLINE_AREAS.DESCRIPTION}'
      '${HEADLINE_AREAS.BANNER}';
    row-gap: 48px;

    ${mq.md} {
      grid-template-areas:
        '${HEADLINE_AREAS.BREADCRUMBS}'
        '${HEADLINE_AREAS.TITLE}'
        '${HEADLINE_AREAS.BANNER}'
        '${HEADLINE_AREAS.AUTHOR}'
        '${HEADLINE_AREAS.DESCRIPTION}';
      row-gap: 32px;
    }
  `}
`;

const StyledAuthor = styled(Author)`
  grid-area: ${HEADLINE_AREAS.AUTHOR};
`;
const StyledBreadcrumbs = styled(Breadcrumbs)`
  grid-area: ${HEADLINE_AREAS.BREADCRUMBS};
`;

const StyledDescription = styled(Description)`
  grid-area: ${HEADLINE_AREAS.DESCRIPTION};
`;

const StyledBanner = styled(Banner)`
  grid-area: ${HEADLINE_AREAS.BANNER};
`;

const Title = styled(RichText)`
  ${({ theme: { mq, tp } }) => css`
    grid-area: ${HEADLINE_AREAS.TITLE};

    ${tp.useFont(fonts => fonts.Inter.semiBold)}
    font-size: 64px;
    line-height: 77.5px;
    margin: 0;

    ${mq.md} {
      font-size: 26px;
      line-height: 31.5px;
    }
  `}
`;

const HeroSection: React.FC<Classname> = ({ className }) => {
  const { title, compact_title } = useBlogArticle().blogArticle.data;

  return (
    <Container className={className}>
      <StyledAuthor />
      <StyledBreadcrumbs />
      <Title render={title?.richText ?? compact_title ?? 'Shakespeare Music'} />
      <StyledDescription />
      <StyledBanner />
    </Container>
  );
};

export const titleFragment = graphql`
  fragment BlogArticleHeroTitleData on PrismicBlogArticleDataType {
    compact_title
    title {
      richText
    }
  }
`;

export const heroSectionFragment = graphql`
  fragment BlogArticleHeroData on PrismicBlogArticle {
    data {
      ...BlogArticleHeroAuthorData
      ...BlogArticleHeroBannerData
      ...BlogArticleHeroDescriptionData
      ...BlogArticleHeroBreadcrumbsData
      ...BlogArticleHeroTitleData
    }
  }
`;

export default HeroSection;
