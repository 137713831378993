import { useMemo } from 'react';
import { Elements } from 'prismic-reactjs';
import { useTheme } from '@emotion/react';

import { useBlogArticle } from '../BlogArticleContext';

import { normalizeRichTextImageContent, richTextWithPlaceholder } from 'utils/prismic';

export const useLegendData = () => {
  const { chapters } = useBlogArticle().blogArticle.data;

  const chapterTitles = chapters.map(
    ({ chapter_title }, index) => chapter_title?.text ?? `Section ${index}`
  );

  return chapterTitles.map((title, index) => {
    const uniqueId = chapterTitles.slice(0, index).includes(title) ? `${title}-${index}` : title;

    return {
      id: `${uniqueId.toLowerCase().split(' ').join('-')}`,
      title,
    };
  });
};

export const useFloatingQuoteData = () => {
  const {
    floating_quote_heading,
    floating_quote_description,
    floating_quote_button_text,
    floating_quote_button_link,
  } = useBlogArticle().blogArticle.data;

  return {
    heading: richTextWithPlaceholder(floating_quote_heading, '', Elements.heading5),
    description: richTextWithPlaceholder(floating_quote_description, ''),
    button: {
      text: floating_quote_button_text ?? 'Get a quote',
      link: floating_quote_button_link?.url ?? '#',
      target: floating_quote_button_link?.target ?? void 0,
    },
  };
};

export const useChaptersData = () => {
  const { chapters } = useBlogArticle().blogArticle.data;
  const legendData = useLegendData();
  const { colors } = useTheme();

  return useMemo(
    () =>
      chapters.map(({ chapter_title, chapter_content }, chapterIndex) => {
        const { id, title } = legendData[chapterIndex];

        return {
          id,
          title: richTextWithPlaceholder(
            chapter_title,
            `Section ${chapterIndex}`,
            Elements.heading3
          ),
          content: normalizeRichTextImageContent(richTextWithPlaceholder(chapter_content, ''), {
            keyBuilder: index => `${id}-${index}`,
            altBuilder: () => title,
            placeholderColor: colors.lighterGray,
          }),
        };
      }),
    [chapters, legendData, colors]
  );
};
