import { useBlogArticle } from '../BlogArticleContext';

export const useSourcesData = () => {
  const { sources } = useBlogArticle().blogArticle.data;

  return (
    sources
      ?.map(({ source }) => source?.richText ?? null)
      .filter(nullableSource => !!nullableSource) ?? []
  );
};
