import { Elements } from 'prismic-reactjs';

import { useBlogArticle } from '../BlogArticleContext';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useFaqData = () => {
  const { frequently_asked_questions } = useBlogArticle().blogArticle.data;

  return (
    frequently_asked_questions?.map(({ question, answer }, index) => {
      const snakeCaseKey = question?.text ?? '';
      return {
        key: `question-${index}-${snakeCaseKey}`,
        question: richTextWithPlaceholder(question, `Question ${index}`, Elements.heading4),
        answer: richTextWithPlaceholder(answer, ''),
      };
    }) ?? []
  );
};
