import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { usePopularArticlesData } from './hooks';

import BlogArticleThumbnails from 'components/BlogArticleThumbnails';
import { Classname } from 'utils/types';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 48px;

    ${mq.md} {
      row-gap: 32px;
    }
  `}
`;

const Title = styled.h2`
  ${({ theme: { mq, tp } }) => css`
    ${tp.useFont(fonts => fonts.Inter.semiBold)}
    font-size: 64px;
    line-height: 77px;
    margin: 0;

    ${mq.md} {
      font-size: 26px;
      line-height: 31.5px;
    }
  `}
`;

const PopularArticlesSection: React.FC<Classname> = ({ className }) => {
  const popularArticles = usePopularArticlesData();

  return popularArticles.length ? (
    <Container className={className}>
      <Title>Popular articles</Title>
      <BlogArticleThumbnails thumbnailsData={popularArticles} />
    </Container>
  ) : null;
};

export const relatedTopicsSectionFragment = graphql`
  fragment BlogArticlePopularArticlesData on PrismicBlogArticle {
    data {
      popular_articles {
        popular_article {
          document {
            ... on PrismicBlogArticle {
              ...BlogArticleThumbnailData
            }
          }
        }
      }
    }
  }
`;

export default PopularArticlesSection;
