import { useBlogArticle } from './BlogArticleContext';

import { LANGUAGE_LOCALES } from 'utils/constants';
import { useLayoutProps, TAlternateLanguages, baseLanguages } from 'components/Layout';

export const useBlogArticleData = () => {
  const { blogArticle, blogArchives } = useBlogArticle();
  const { lang, alternate_languages } = blogArticle;

  const blogArchiveFallbackUrls = baseLanguages.reduce((acm, baseLanguage) => {
    const blogArchiveIndex = blogArchives.edges.findIndex(
      ({ node: blogArchive }) => blogArchive.lang === baseLanguage
    );

    if (blogArchiveIndex > -1) {
      const { url } = blogArchives.edges[blogArchiveIndex].node;
      return { ...acm, [baseLanguage]: url };
    }

    return { ...acm, [baseLanguage]: null };
  }, {}) as Record<LANGUAGE_LOCALES, string | null>;

  return {
    layoutProps: {
      ...useLayoutProps({
        lang,
        alternate_languages: alternate_languages as TAlternateLanguages,
        fallbackUrls: [blogArchiveFallbackUrls],
        isDark: true,
      }),
    },
  };
};
