import { useBlogArticle } from '../BlogArticleContext';

export const usePopularArticlesData = () => {
  const { popular_articles } = useBlogArticle().blogArticle.data;

  return (
    popular_articles?.map(
      node => node.popular_article.document as Queries.BlogArticleThumbnailDataFragment
    ) ?? []
  );
};
