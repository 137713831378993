import React from 'react';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';

import { useBannerData } from './hooks';

import { Classname } from 'utils/types';

const DesktopBanner = styled(GatsbyImage)<{ breakpoint: number }>`
  ${({ theme: { mq }, breakpoint }) => css`
    display: block;
    border-radius: 16px;
    ${mq.use(breakpoint)} {
      display: none;
    }
  `}
`;

const MobileBanner = styled(DesktopBanner)<{ 'max-height': number }>`
  ${({ theme: { mq }, breakpoint, ...props }) => css`
    width: 100%;
    display: none;
    max-height: ${props['max-height']}px;
    ${mq.use(breakpoint)} {
      display: block;
    }
  `}
`;

const Banner: React.FC<Classname> = ({ className }) => {
  const bannerImage = useBannerData();

  if (!bannerImage) return null;

  const { breakpoint, desktop, mobile } = bannerImage;

  return (
    <>
      <DesktopBanner
        className={className}
        breakpoint={breakpoint}
        alt={desktop.alt}
        image={desktop.image}
      />
      <MobileBanner
        className={className}
        breakpoint={breakpoint}
        alt={mobile.alt}
        image={mobile.image}
        max-height={mobile.maxHeight}
      />
    </>
  );
};

export const bannerFragment = graphql`
  fragment BlogArticleHeroBannerData on PrismicBlogArticleDataType {
    banner_image {
      dimensions {
        width
        height
      }
      alt
      gatsbyImageData(placeholder: BLURRED)
      thumbnails {
        Mobile {
          dimensions {
            width
            height
          }
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;

export default Banner;
