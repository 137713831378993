import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import BlogArticle from './BlogArticle';

import { withHead } from 'components/SEO';

export const Head = withHead<Queries.BlogArticleQuery>('blogArticle');

export const query = graphql`
  query BlogArticle($id: String) {
    blogArticle: prismicBlogArticle(id: { eq: $id }) {
      _previewable
      lang
      alternate_languages {
        document {
          ... on PrismicBlogArticle {
            lang
            url
          }
        }
      }

      ...SEOData
      ...BlogArticleData
    }

    blogArchives: allPrismicBlogArchive {
      edges {
        node {
          data {
            title
          }
          lang
          url
        }
      }
    }
  }
`;

export type AllBlogArticlesQuery = Queries.AllBlogArticlesQuery;

export default withPrismicPreview(BlogArticle);
