import { useBlogArticle } from '../BlogArticleContext';

export const useAuthorData = () => {
  const { author } = useBlogArticle().blogArticle.data;

  if (!author || !author.document) return null;

  const {
    uid,
    url,
    data: { name, occupation, description, facebook, instagram, linkedin, twitter },
  } = author.document as Queries.PrismicAuthor;

  return {
    url: url as string,
    name: name ?? uid,
    occupation,
    description: description?.richText ?? null,
    facebook: facebook?.url ?? null,
    instagram: instagram?.url ?? null,
    linkedIn: linkedin?.url ?? null,
    twitter: twitter?.url ?? null,
  };
};
