import { Elements } from 'prismic-reactjs';

import { useBlogArticle } from '../BlogArticleContext';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useStaticQuoteData = () => {
  const {
    static_quote_heading,
    static_quote_description,
    static_quote_button_text,
    static_quote_button_link,
  } = useBlogArticle().blogArticle.data;

  return {
    heading: richTextWithPlaceholder(static_quote_heading, '', Elements.heading5),
    description: richTextWithPlaceholder(static_quote_description, ''),
    button: {
      text: static_quote_button_text ?? 'Get a quote',
      link: static_quote_button_link?.url ?? '#',
      target: static_quote_button_link?.target ?? void 0,
    },
  };
};
